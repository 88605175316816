import React, { useState, useEffect, useCallback } from 'react';
import { navigate } from 'gatsby';
import { useForm } from '@formspree/react';
import { Flex, Box } from 'rebass/styled-components';
import { BsCheckCircleFill, BsFillXCircleFill } from 'react-icons/bs';

import Container from '@/components/Layout/Container';
import ContactForm from '@/components/ContactForm/ContactForm';
import Text from '@/components/Text/Text';
import Modal from '@/components/Modal/Modal';
import Result from '@/components/Result/Result';
import Loader from '@/components/Loader/Loader';

const FormSubmitting = () => <Result icon={<Loader />} title="Envoi..." />;

const FormSubmitSuccess = () => (
  <Result
    icon={<BsCheckCircleFill color="#52CFC5" />}
    title="Le formulaire a bien été envoyé"
    subtitle="Vous allez être redirigé vers la page d'accueil"
  />
);

const FormSubmitError = () => (
  <Result
    icon={<BsFillXCircleFill color="#f85170" />}
    title="Une erreur est survenue"
    subtitle="Veuillez réessayer plus tard"
  />
);

const ContactPage = () => {
  const [showModal, setShowModal] = useState(false);
  const [formState, submitForm] = useForm('mwkyollv');

  const { succeeded, submitting, errors } = formState;

  useEffect(() => {
    if (submitting || succeeded || errors.length > 0) {
      setShowModal(true);
    }
  }, [submitting, succeeded, errors.length]);

  const handleExitModal = useCallback(() => {
    if (submitting || succeeded) {
      return;
    }
    setShowModal(false);
  }, [submitting, succeeded]);

  useEffect(() => {
    if (succeeded) {
      setTimeout(() => navigate('/'), 3000);
    }
  }, [succeeded]);

  // eslint-disable-next-line no-nested-ternary
  const ModalChildren = submitting
    ? FormSubmitting
    : succeeded
    ? FormSubmitSuccess
    : FormSubmitError;

  return (
    <>
      <Modal show={showModal} onHide={handleExitModal}>
        <ModalChildren />
      </Modal>
      <Container>
        <Box py="3rem" mx="auto" width={['100%', '100%', '600px']}>
          <Flex flexDirection="column" justifyContent="center">
            <Text
              textStyle="secondaryHeading"
              as="h2"
              mb="2rem"
              textAlign="center"
            >
              Soyez prévenu dès la sortie !
            </Text>
            <ContactForm
              onSubmit={submitForm}
              submitting={formState.submitting}
            />
          </Flex>
        </Box>
      </Container>
    </>
  );
};

export default ContactPage;
