import React from 'react';
import styled from 'styled-components';
import { LayoutProps, layout, MarginProps, margin } from 'styled-system';
import { Flex, Box, Button } from 'rebass/styled-components';
import { BsInstagram, BsYoutube } from 'react-icons/bs';
import { CgWebsite } from 'react-icons/cg';

import Text from '../Text/Text';

const FormWrapper = styled.form`
  padding: 2rem 1.5rem;

  .formfield:not(:last-child) {
    margin-bottom: 2rem;
  }

  button {
    display: flex;
    align-items: center;
    cursor: pointer;
    min-width: 48px;
    border-radius: 25px;
  }
`;

const Input = styled.input<LayoutProps & MarginProps>`
  padding: 1.2rem 1.5rem;
  font-family: inherit;
  outline: none;
  color: ${(props) => props.theme.colors.primaryText};
  border-radius: 8px;
  background-color: rgba(#fff, 0.5);
  border: 2px solid #e0e0e0;
  text-overflow: ellipsis;
  transition: all 0.3s;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &::-webkit-input-placeholder {
    color: rgba(${(props) => props.theme.colors.secondaryText}, 0.5);
  }

  &:focus {
    outline: none;
    box-shadow: 0 1rem 2rem rgba(255, 255, 255, 0.1);
    border: 2px solid ${(props) => props.theme.colors.secondaryText};
    background-color: #fff;
  }

  &:focus:invalid {
    border: 2px solid ${(props) => props.theme.colors.secondaryText};
  }

  ${layout}
  ${margin}
`;

const Label = styled.label<LayoutProps & MarginProps>`
  color: ${(props) => props.theme.colors.primaryText};
  font-weight: 700;
  font-size: 1.4rem;

  ${layout}
  ${margin}
`;

const FormField = styled(Flex)`
  input {
    font-weight: 400;
    font-size: 16px;
  }
`;

const bottomMarginSocialNetworkLabels = ['8px', '8px', '0'];
const bottomMarginLabels = ['8px', '8px', '1.2rem'];

interface ContactFormProps {
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  submitting: boolean;
}

const ContactForm: React.FC<ContactFormProps> = ({ onSubmit, submitting }) => (
  <FormWrapper autoComplete="off" onSubmit={onSubmit}>
    <FormField
      className="formfield"
      flexDirection="column"
      alignItems="stretch"
    >
      <Label htmlFor="prenom" marginBottom={bottomMarginLabels}>
        Prénom
      </Label>
      <Input required name="prenom" id="prenom" type="text" />
    </FormField>
    <FormField className="formfield" flexDirection="column">
      <Label htmlFor="nom" marginBottom={bottomMarginLabels}>
        Nom
      </Label>
      <Input required name="nom" id="nom" type="text" />
    </FormField>
    <FormField className="formfield" flexDirection="column">
      <Label htmlFor="email" marginBottom={bottomMarginLabels}>
        Email
      </Label>
      <Input required name="email" id="email" type="email" />
    </FormField>
    <Box my="3.5rem">
      <Text textStyle="tertiaryHeading">Réseaux sociaux</Text>
    </Box>
    <FormField
      className="formfield"
      flexDirection={['column', 'row']}
      justifyContent={['normal', 'space-between']}
      alignItems={['normal', 'center']}
    >
      <Label
        htmlFor="instagram_profile"
        marginBottom={bottomMarginSocialNetworkLabels}
      >
        <Flex alignItems="center">
          <div className="instagramIcon">
            <BsInstagram size={20} />
          </div>
          <Text mx="8px" textStyle="tertiaryHeading">
            Instagram
          </Text>
        </Flex>
      </Label>
      <Input
        id="instagram_profile"
        name="instagram_profile"
        type="text"
        placeholder="@"
        required
      />
    </FormField>
    <FormField
      className="formfield"
      flexDirection={['column', 'row']}
      justifyContent={['normal', 'space-between']}
      alignItems={['normal', 'center']}
    >
      <Label
        htmlFor="youtube_profile"
        marginBottom={bottomMarginSocialNetworkLabels}
      >
        <Flex alignItems="center">
          <BsYoutube size={20} />
          <Text mx="8px" textStyle="tertiaryHeading">
            Youtube{' '}
            <Text as="i" textStyle="displaySmall">
              (optionnel)
            </Text>
          </Text>
        </Flex>
      </Label>
      <Input
        id="youtube_profile"
        name="youtube_profile"
        type="text"
        placeholder="/"
      />
    </FormField>
    <FormField
      className="formfield"
      flexDirection={['column', 'row']}
      justifyContent={['normal', 'space-between']}
      alignItems={['normal', 'center']}
    >
      <Label
        htmlFor="website_url"
        marginBottom={bottomMarginSocialNetworkLabels}
      >
        <Flex alignItems="center">
          <CgWebsite size={20} />
          <Text mx="8px" textStyle="tertiaryHeading">
            Site Web{' '}
            <Text as="i" textStyle="displaySmall">
              (optionnel)
            </Text>
          </Text>
        </Flex>
      </Label>
      <Input
        id="website_url"
        name="website_url"
        type="text"
        placeholder="URL"
      />
    </FormField>
    <Flex py="15px" alignItems="center" justifyContent="center">
      <Button
        type="submit"
        px="20px"
        py="15px"
        textAlign="center"
        fontFamily="inherit"
        bg="primary"
        disabled={submitting}
      >
        <Text textStyle="displayMedium">Envoyer</Text>
      </Button>
    </Flex>
  </FormWrapper>
);

export default ContactForm;
