import React, { FC } from 'react';
import NativeModal, {
  ModalProps,
  RenderModalBackdropProps,
} from 'react-overlays/Modal';
import { CSSTransition } from 'react-transition-group';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  0% {
    background: rgba(0,0,0,.0);
  }
  100% {
    background: rgba(0,0,0,.5);
  }
`;

const fadeOut = keyframes`
  0% {
    background:rgba(0,0,0,.5);
  }
  100% {
    background:rgba(0,0,0,.0);
  }
`;

const scaleUp = keyframes`
  0% {
    transform:scale(1) translate(-50%, 1000px);
    opacity:0;
  }
  100% {
    transform:scale(1) translate(-50%; -50%);
    opacity:1;
  }
`;

const scaleDown = keyframes`
  0% {
    transform:scale(1) translate(-50%; -50%);
    opacity:1;
  }
  100% {
    transform:scale(1) translate(-50%, 1000px);
    opacity:0;
  }
`;

const TRANSITION_DURATION = 500;
const BACKDROP_TRANSITION_NAME = 'backdrop';
const MODAL_TRANSITION_NAME = 'modal';

const BackdropFade = ({ children, ...props }) => (
  <CSSTransition
    {...props}
    timeout={TRANSITION_DURATION}
    classNames={BACKDROP_TRANSITION_NAME}
  >
    {children}
  </CSSTransition>
);

const ModalScale = ({ children, ...props }) => (
  <CSSTransition
    {...props}
    timeout={TRANSITION_DURATION}
    classNames={MODAL_TRANSITION_NAME}
  >
    {children}
  </CSSTransition>
);

const BackdropWrapper = styled.div`
  position: fixed;
  z-index: 1040;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;

  &.${BACKDROP_TRANSITION_NAME}-appear, &.${BACKDROP_TRANSITION_NAME}-enter {
    background: rgba(0, 0, 0, 0.8);
  }

  &.${BACKDROP_TRANSITION_NAME}-appear-active,
    &.${BACKDROP_TRANSITION_NAME}-enter-active,
    &.${BACKDROP_TRANSITION_NAME}-appear-done,
    &.${BACKDROP_TRANSITION_NAME}-enter-done {
    background: rgba(0, 0, 0, 0);
    animation: ${fadeIn} 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }

  &.${BACKDROP_TRANSITION_NAME}-exit {
    background: rgba(0, 0, 0, 0);
  }

  &.${BACKDROP_TRANSITION_NAME}-exit-active {
    animation: ${fadeOut} 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }
`;

const ModalWrapper = styled(NativeModal)`
  position: fixed;
  z-index: 1040;
  background-color: white;
  width: 80%;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 1.5rem;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  box-sizing: border-box;
  transition: all 0.3s ease-out;

  &.${MODAL_TRANSITION_NAME}-appear, &.${MODAL_TRANSITION_NAME}-enter {
  }

  &.${MODAL_TRANSITION_NAME}-appear-active,
    &.${MODAL_TRANSITION_NAME}-enter-active,
    &.${MODAL_TRANSITION_NAME}-appear-done,
    &.${MODAL_TRANSITION_NAME}-enter-done {
    animation: ${scaleUp} 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }

  &.${MODAL_TRANSITION_NAME}-exit {
  }

  &.${MODAL_TRANSITION_NAME}-exit-active {
    animation: ${scaleDown} 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }

  @media (min-width: 600px) {
    width: 500px;
  }
`;

const Backdrop: FC<RenderModalBackdropProps> = (props) => (
  <BackdropWrapper {...props} onTouchMove={(e) => e.preventDefault()} />
);
const Modal: FC<ModalProps> = ({ children, ...rest }) => (
  <ModalWrapper
    renderBackdrop={Backdrop}
    backdropTransition={BackdropFade}
    transition={ModalScale}
    {...rest}
  >
    {children}
  </ModalWrapper>
);

export default Modal;
