import React, { ReactNode } from 'react';
import { Flex, Box } from 'rebass/styled-components';

import Text from '@/components/Text/Text';

interface ResultProps {
  icon?: ReactNode;
  title?: ReactNode;
  subtitle?: ReactNode;
  extra?: ReactNode;
}

const Result: React.FC<ResultProps> = ({
  icon,
  title,
  subtitle,
  extra,
  children,
}) => (
  <Flex flexDirection="column" alignItems="center" pt="3rem" pb="1rem">
    {icon && (
      <Box pb="1.5rem" fontSize="5rem">
        {icon}
      </Box>
    )}
    {(title || subtitle) && (
      <Flex flexDirection="column" pb="2.5rem" color="primaryText">
        {title && (
          <Text textStyle="displayLarge" mb="1rem" textAlign="center">
            {title}
          </Text>
        )}
        {subtitle && (
          <Text
            textStyle="displaySmall"
            color="secondaryText"
            mb="1rem"
            textAlign="center"
          >
            {subtitle}
          </Text>
        )}
      </Flex>
    )}
    {extra && (
      <Flex
        sx={{
          '& > *:not(:last-child)': {
            marginRight: '1.5rem',
          },
        }}
      >
        {extra}
      </Flex>
    )}
    {children}
  </Flex>
);

Result.defaultProps = {
  icon: null,
  title: null,
  subtitle: null,
  extra: null,
};

export default Result;
