import React from 'react';
import ContactPage from '@/containers/ContactPage/ContactPage';
import SEO from '@/components/SEO/SEO';

const Contact = () => (
  <>
    <SEO title="Contact" />
    <ContactPage />
  </>
);

export default Contact;
